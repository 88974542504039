<template>
  <v-container
    fluid
    tag="section"
    data-cy="pageProgressionUsager"
  >
    <v-row>
      <v-col cols="12">
        <base-material-card
          color="success"
          inline
          title="Progression d'un usager"
          class="px-5 py-3"
        >
          <v-card-text>
            <v-row justify="center">
              <v-col
                cols="12"
                sm="4"
              >
                <progression-usager-level-card :progression="dossierProgression" />
              </v-col>
            </v-row>
          </v-card-text>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import restApiService from '@/services/restApiService.js'
  import ProgressionUsagerLevelCard from '../composants/ProgressionUsagerLevelCard.vue'

  export default {
    components: {
      ProgressionUsagerLevelCard,
    },
    data: () => ({
      usager: {
        dossierProgression: {
          niveau: '',
          pointage: '',
        },
      },
      dossierProgression: {
        best: true,
        titre: 'Progression usager',
        icone: 'mdi-medal-outline',
        niveau: '9',
        score: '40',
      },
    }),
    mounted () {
      this.charger()
    },
    methods: {
      charger () {
        restApiService.get('/api/usagers/' + this.$route.params.id).then((res) => {
          this.dossierProgression.niveau = res.data.dossierProgression.niveau
          this.dossierProgression.score = res.data.dossierProgression.pointage
        })
      },

    },
  }
</script>

<style>

</style>
