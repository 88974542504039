<template>
  <v-card
    :color="!progression.best ? 'transparent elevation-0' : undefined"
    :light="progression.best"
    class="v-card--plan mx-auto pt-3 pb-4 px-2 text-center"
    max-width="100%"
  >
    <div
      class="body-2 text-uppercase grey--text"
      v-text="progression.titre"
    />

    <v-avatar
      size="130"
    >
      <v-icon
        :color="!progression.best ? undefined : 'success'"
        size="64"
        v-text="progression.icone"
      />
    </v-avatar>

    <h2
      class="display-2 font-weight-light"
      data-cy="txtNiveauUsager"
    >
      <b>Niveau:</b> {{ progression.niveau }}
    </h2>

    <p
      class="body-1 font-weight-light pa-1"
      data-cy="txtScoreUsager"
    >
      <b>Score:</b> {{ progression.score }}
    </p>
  </v-card>
</template>

<script>
  export default {
    name: 'ProgressionUsagerLevelCard',

    components: {

    },

    props: {
      progression: {
        type: Object,
        default: () => ({
          best: false,
          titre: undefined,
          icone: undefined,
          niveau: undefined,
          score: undefined,
        }),
      },
    },
  }
</script>

<style lang="sass">
  .v-card--plan
    .v-avatar
      border-radius: 50%
      border: 1px solid #E5E5E5
</style>
